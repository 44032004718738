import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Layout from './layout'
import Image from './image'
import SEO from './seo'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import { Link } from 'gatsby'
import { ListItem, ListItemText, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}))

const IndexPage = ({ pageContext: { toc } }) => {
  const classes = useStyles()

  return (
    <Layout drawerOpen={true} navPages={toc}>
      <SEO title="Home" />

      <Typography variant="h5">
        About this content
      </Typography>

      <Typography variant="body1">
        <p>
        Tararua Footprints A Tramper's Guide to the Tararua Ranges by Merv Rodgers was first 
        published by the Canterbury University Press in 1996.
        </p>

        <p>
          The Tararua Tramper's guide now maintained by the Tararua Tramping Club, and is being 
          updated by a team led by John Thomson.
        </p>
    
        <p>
          The Tararua Footprints guide in this format is prepared by 
          <a href="https://joshmcarthur.com">Josh McArthur</a>.
        </p>

        <p>
          Book content is licensed under the Creative Commons
          Attribution-NonCommercial-NoDerivs 3.0 New Zealand (CC BY-NC-ND 3.0 NZ) license.
        </p>

        <p>
          This site is <a href="https://gitlab.com/joshmcarthur/footprints">open-sourced</a>, 
          and is licensed under GNU Public License v3.
        </p>
      </Typography>
    </Layout>
  )
}

export default IndexPage
